<template>
	<div class="adomany">
		<div class="promoKapcsolatHeader">
			<div class="grid">
				<div class="col-4 md:col-4 lg:col-4 lead leadHeader kapcsolat">Kapcsolat</div>
			</div>
			<div class="grid">
				<div class="col-6 md:col-6 lg:col-4 lead"><div class="leadText">A háborúból menekülők támogatására szánt pénzadományokat az alábbi számlaszámon fogadunk:</div></div>
			</div>
		</div>

		<div class="grid justify-content-center">
			<div class="col-12 md:col-10 lg:col-8 contentText kapcsolatContent">
				<div class="adomanyTitle">
					<div>Váci Egyházmegyei Karitász</div>
					<div>2600 Vác, Hattyú u. 1.</div>
					<div>Tel.: +36 27 814 174</div>
					<div>E-mail: caritas@vaciegyhazmegye.hu</div>
				</div>
				<div class="tevekenysegBorder">
					<table class="adomanyDescripionTitle">
						<tr>
							<td class="adomanyDescriptionIcon"></td>
							<td class="adomanyDescription">
								Tel.: +36 27 814 174
							</td>
						</tr>
						<tr>
							<td class="adomanyDescriptionIcon"></td>
							<td class="adomanyDescription">
								E-mail: caritas@vaciegyhazmegye.hu 
							</td>
						</tr>
						<tr>
							<td class="adomanyDescriptionIcon"></td>
							<td class="adomanyDescription">
								<div>Adószám: 18290691-1-13</div>
								<div>Nyilvántartási szám: 00001/2012-024</div>
							</td>
						</tr>
						<tr>
							<td class="adomanyDescriptionIcon"></td>
							<td class="adomanyDescription">
								<div>Külföldről történő utalás esetén:</div>
								<div>&nbsp;</div>
								<div>SWIFT</div>
								<div>HU42117840092222714800000000</div>
								<div>&nbsp;</div>
								<div>IBAN</div>
								<div>OTPVHUHB</div>
								<div>&nbsp;</div>
							</td>
						</tr>
						<tr>
							<td class="adomanyDescriptionIcon"></td>
							<td class="adomanyDescription">
								A közleménybe kérjük beírni: "ukrajnai menekültek".
							</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
		<div class="grid">
			<div class="col-12 md:col-12 lg:col-12 adomanyFooter">
				<AppFooter />
			</div>
		</div>
    </div>
</template>

<script>
import AppFooter from '../AppFooter.vue';
export default {
    components: {
        'AppFooter': AppFooter,
    },
	mounted() {
		this.$appState.myCart = true;
	},
}
</script>

<style lang="scss" scoped>

</style>
